import React from "react";

import { Button } from "@mui/material";
import clsx from "clsx";
import { Field } from "formik";

import CheckFieldDisabled from "@shared/CheckFieldDisabled";
import RequiredAsterisk from "@shared/forms/RequiredAsterisk";
import SelectField from "@shared/forms/SelectField";
import HelperIcon from "@shared/helpers/HelperIcon";
import HtmlEditorField from "@shared/HtmlEditorField";
import {
  MuiTextField,
  MuiTextArea,
  MuiDatePicker,
  MuiDateTimePicker,
  MuiCheckbox,
  MuiCheckboxForcedValue,
  FormikRichTextEditor
} from "@shared/muiformik/FieldConversions";
import RadioField from "@shared/RadioField";
import RegexField from "@shared/RegexField";

const renderField = (
  component,
  label,
  fieldName,
  formatClasses = [],
  disabled = false,
  required = false,
  properties = {}
) => (
  <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
    {properties.disableLabel ? (
      <></>
    ) : (
      <label>
        {label}
        {required ? <RequiredAsterisk /> : <></>}
        {properties.helper ? <HelperIcon {...(properties.helperProperties || {})} /> : <></>}
        {properties.labelAdditions ? properties.labelAdditions : ""}
      </label>
    )}
    <Field
      {...(properties.componentProps||{})}
      component={component ? component : MuiTextField}
      variant={"outlined"}
      size="small"
      fullWidth
      name={fieldName}
      disabled={disabled}
      placeholder={properties.placeholder}
      // Chrome ignores most autocomplete: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
      autoComplete="one-time-code"
      {...(properties.fieldProperties || {})}
    />
  </div>
);

const renderCancelButton = (label, onClick, opts = {}) => (
  <Button variant="outlined" type="button" color={opts.color} onClick={onClick}>
    {label}
  </Button>
);

const renderButton = (label, onClick, opts = {}) => (
  <Button
    variant={opts.variant || "contained"}
    type={opts.type || "button"}
    color={opts.color}
    onClick={onClick}
    disabled={!!opts["disabled"]}
  >
    {label}
  </Button>
);

const renderCheckField = (label, fieldName, formatClasses = [], properties = {}) => (
  <div className="sg-mgmt-form-row" key={`${fieldName}-${label}`}>
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <Field
        component={MuiCheckbox}
        variant={"outlined"}
        size="small"
        name={fieldName}
        label={label}
        disabled={properties.disabled}
        placeholder={properties.placeholder}
      />
    </div>
  </div>
);

const renderCheckFieldForcedValue = (label, fieldName, value, properties = {}) => (
  <div className="sg-mgmt-form-row" key={`${fieldName}-${label}`}>
    <div className={clsx("sg-mgmt-form-input-container")}>
      <Field
        component={MuiCheckboxForcedValue}
        checkValue={value}
        variant={"outlined"}
        size="small"
        fullWidth
        name={fieldName}
        label={label}
        disabled={properties.disabled}
        placeholder={properties.placeholder}
      />
    </div>
  </div>
);

const renderCheckFieldDisabled = (label, checked, formatClasses = []) => (
  <div className="sg-mgmt-form-row" key={`${label}`}>
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <CheckFieldDisabled checked={checked} label={label} />
    </div>
  </div>
);

const renderDateTimeField = (label, fieldName, formatClasses = [], properties = {}) =>
  renderField(MuiDateTimePicker, label, fieldName, formatClasses, properties.disabled, properties.required, {
    ...properties
  });

const renderDateField = (label, fieldName, formatClasses = [], properties = {}) =>
  renderField(MuiDatePicker, label, fieldName, formatClasses, properties.disabled, properties.required, {
    ...properties
  });

const renderHtmlEditorField = (label, fieldName, formatClasses = [], required = false, properties = {}) => (
  <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
    <label>
      {label}
      {required ? <RequiredAsterisk /> : <></>}
    </label>
    <HtmlEditorField
      className={clsx("sg-mgmt-form-input", "sg-mgmt-form-input-html")}
      name={fieldName}
      autoComplete="off"
      {...(properties.fieldProperties || {})}
    />
  </div>
);

const renderInfo = (label, value) => {
  return (
    <div key={label} className="sg-mgmt-modal-view-field mb-1">
      <span className="sg-mgmt-modal-view-field-label">{label}</span>
      :&nbsp;
      {value}
    </div>
  );
};

const renderIntegerField = (
  label,
  fieldName,
  formatClasses = [],
  minimum = 0,
  step = 1,
  maximum = 2147483648,
  disabled = false,
  required = false
) => (
  <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
    <label>
      {label}
      {required ? <RequiredAsterisk /> : <></>}
    </label>
    <Field
      component={MuiTextField}
      className="sg-mgmt-form-input"
      type="number"
      as="input"
      inputProps={{
        min: minimum,
        max: maximum,
        step: step
      }}
      name={fieldName}
      disabled={disabled}
      autoComplete="off"
    />
  </div>
);

const renderRadioField = (fieldName, options, integer = false) => (
  <RadioField fieldName={fieldName} options={options} integer={integer} />
);

const renderRegexField = (label, fieldName, regex, formatClasses = [], properties = {}) => (
  <div className={clsx("sg-mgmt-form-input-container", "sg-mgmt-form-input-slug", formatClasses)}>
    <label>
      {label} {properties.required ? <RequiredAsterisk /> : <></>}
    </label>
    <RegexField
      className="sg-mgmt-form-input sg-mgmt-form-input-slug"
      regex={regex}
      type="text"
      name={fieldName}
      autoComplete="off"
      {...properties}
    />
  </div>
);

const renderRichTextAreaField = (label, fieldName, formatClasses = [], options = {}) =>
  renderField(FormikRichTextEditor, label, fieldName, formatClasses, options.disabled, options.required, {
    ...options
  });

const renderSelectField = (
  label,
  fieldName,
  options,
  formatClasses = [],
  props = [],
  required = false,
  properties = {}
) => (
  <SelectField
    label={label}
    name={fieldName}
    options={options}
    formatClasses={formatClasses}
    props={props}
    required={required}
    autoComplete="off"
    {...properties}
  />
);

const renderSelectFieldFilterable = (
  label,
  fieldName,
  options,
  formatClasses = [],
  props = [],
  required = false,
  properties = {}
) => (
  <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
    <label>
      {label} {required}
      {required ? <RequiredAsterisk /> : <></>}
      {properties.helper ? <HelperIcon {...(properties.helperProperties || {})} /> : <></>}
      {properties.labelAdditions ? properties.labelAdditions : ""}
    </label>
    <SelectField placeholder={properties.placeholder} name={fieldName} options={options} {...props} />
  </div>
);

const renderSubmitButton = (label, disabled = false, properties = {}) => (
  <Button
    variant={properties.variant || "contained"}
    color={properties.color || "primary"}
    size="medium"
    type="submit"
    disabled={disabled}
    className={properties.className || ""}
  >
    {label}
  </Button>
);

const renderCreateButton = (label, onClick, disabled = false, properties = {}) => (
  <Button
    variant="contained"
    color="secondary"
    size="medium"
    disabled={disabled}
    type="button"
    onClick={onClick}
    className={properties.className || "my-3"}
  >
    {label}
  </Button>
);

const renderDeleteButton = (label, onClick, disabled = false) => (
  <Button variant="outlined" color="error" size="medium" type="button" disabled={disabled} onClick={onClick}>
    {label}
  </Button>
);

const renderTextField = (label, fieldName, formatClasses = [], disabled = false, required = false, properties = {}) => {
  return renderField(MuiTextField, label, fieldName, formatClasses, disabled, required, properties);
};

const renderTextAreaField = (label, fieldName, formatClasses = [], disabled = false, properties = {}) => {
  return renderField(MuiTextArea, label, fieldName, formatClasses, disabled, properties.required, { ...properties });
};

export {
  renderCancelButton,
  renderDeleteButton,
  renderCheckField,
  renderCheckFieldDisabled,
  renderCreateButton,
  renderDateField,
  renderDateTimeField,
  renderHtmlEditorField,
  renderInfo,
  renderIntegerField,
  renderField,
  renderSelectField,
  renderSelectFieldFilterable,
  renderSubmitButton,
  renderButton,
  renderRadioField,
  renderRegexField,
  renderRichTextAreaField,
  renderTextField,
  renderTextAreaField,
  renderCheckFieldForcedValue
};
