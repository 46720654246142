import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import { Formik, Form } from "formik";
import { includes, lowerCase, orderBy } from "lodash";
import urljoin from "url-join";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import Button from "@shared/Button";
import CheckFieldSmall from "@shared/CheckFieldSmall";
import CheckFieldSmallDisabled from "@shared/CheckFieldSmallDisabled";
import { renderTextField } from "@shared/FormUtils";
import SelectField from "@shared/SelectField";
import { isDeveloper } from "@shared/UserUtils";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { renderCancelButton, renderButton, renderSubmitButton} from "@shared/FormUtils";
import { Stack } from "@mui/material";
import { renderSelectField } from "@shared/FormUtils";

const EventUsersForm = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { eventUsers, reset, update, user, users } = props;
  const { user: currentUser } = useContext(EventUserContext);

  const isEdit = () => {
    return user && user.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-user-edit",
        formUrl: urljoin(apiRoot, "/users", `/${user.id}`),
        method: "PATCH",
        title: "Edit User"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-user-add",
      formUrl: urljoin(apiRoot, "/users"),
      method: "POST",
      title: "Add User"
    };
  })();

  const performReset = id => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/users", `/${id}`, "/reset"),
      method: "PATCH"
    }).then(response => {
      if (response.data.error === null) {
        alertSuccess("Password reset sent");
      } else {
        alertError(response.data.error);
      }
    });
  };

  const renderValue = (label, value) => {
    return (
      <div className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderDeveloper = () => {
    if (isDeveloper(currentUser)) {
      return (
        <div className="sg-mgmt-modal-view-section">
          <h2>Developer Details</h2>
          {renderValue("ID", user.id)}
          {renderValue("Current sign in datestamp", user.current_sign_in_at)}
          {renderValue("Current IP", user.current_ip)}
          {renderValue("Sign in count", user.sign_in_count)}
        </div>
      );
    }
    return <></>;
  };

  const formInitialValues = () => {
    return {
      name_last: user.name_last || "",
      name_first: user.name_first || "",
      job_title: user.job_title || "",
      company: user.company || "",
      email: user.email || "",
      id: user.id || "",
      permission: {
        analytics_view: user.permission.analytics_view || false,
        attendees_view: user.permission.attendees_view || false,
        attendees_edit: user.permission.attendees_edit || false,
        attendees_delete: user.permission.attendees_delete || false,
        cache_view: user.permission.cache_view || false,
        communications_view: user.permission.communications_view || false,
        communications_edit: user.permission.communications_edit || false,
        communications_send: user.permission.communications_send || false,
        dashboard_default_edit: user.permission.dashboard_default_edit || false,
        feature_flag_view: user.permission.feature_flag_view || false,
        feature_flag_edit: user.permission.feature_flag_edit || false,
        forms_view: user.permission.forms_view || false,
        forms_edit: user.permission.forms_edit || false,
        housing_view: user.permission.housing_view || false,
        housing_edit: user.permission.housing_edit || false,
        meetings_view: user.permission.meetings_view || false,
        meetings_edit: user.permission.meetings_edit || false,
        meetings_delete: user.permission.meetings_delete || false,
        meeting_requests_view: user.permission.meeting_requests_view || false,
        meeting_requests_edit: user.permission.meeting_requests_edit || false,
        meeting_requests_approval:
          user.permission.meeting_requests_approval || false,
        participants_view: user.permission.participants_view || false,
        participants_edit: user.permission.participants_edit || false,
        participants_delete: user.permission.participants_delete || false,
        reports_view: user.permission.reports_view || false,
        sessions_view: user.permission.sessions_view || false,
        sessions_edit: user.permission.sessions_edit || false,
        sessions_delete: user.permission.sessions_delete || false,
        speakers_view: user.permission.speakers_view || false,
        speakers_edit: user.permission.speakers_edit || false,
        speakers_delete: user.permission.speakers_delete || false,
        static_files_view: user.permission.static_files_view || false,
        static_files_edit: user.permission.static_files_edit || false,
        ticketing_view: user.permission.ticketing_view || false,
        ticketing_edit: user.permission.ticketing_edit || false,
        users_view: user.permission.users_view || false,
        users_edit: user.permission.users_edit || false,
        users_invite: user.permission.users_invite || false,
        web_pages_view: user.permission.web_pages_view || false,
        web_pages_edit: user.permission.web_pages_edit || false
      }
    };
  };

  const renderExistingUsers = (formatClasses = []) => {
    if (isEdit()) {
      return <></>;
    }
    const addedUserIds = eventUsers.map(u => u.id);
    const filteredUsers = users.filter(u => !includes(addedUserIds, u.id));
    const userOptions = [{ label: "", value: "" }].concat(
      orderBy(
        filteredUsers,
        [u => lowerCase(u.name_last), u => lowerCase(u.name_first)],
        "asc"
      ).map(u => ({
        label: `${u.name_last}, ${u.name_first} (${u.email})`,
        value: u.id
      }))
    );
    return (
      <div
        className={clsx("sg-mgmt-form-input-container", formatClasses)}
      >
        {renderSelectField("", "user[id]", userOptions)}
      </div>
    );
  };

  const renderPasswordReset = () => {
    if (isEdit()) {
      return (
        <div className="sg-mgmt-form-row">
          {renderButton("Send password reset email", ()=> {
            performReset(user.id);
          })}
        </div>
      );
    }
    return <></>;
  };

  const renderAddUser = userSelected => {
    if (userSelected) {
      return <></>;
    }
    return (
      <>
        <div className="sg-mgmt-form-row">Or add new user:</div>
        <div className="sg-mgmt-form-row">
          {renderTextField("First Name", "user[name_first]", [], userSelected)}
          {renderTextField("Last Name", "user[name_last]", [], userSelected)}
        </div>
        <div className="sg-mgmt-form-row">
          {renderTextField("Company", "user[company]", [], userSelected)}
        </div>
        <div className="sg-mgmt-form-row">
          {renderTextField("Email Address", "user[email]", [], userSelected)}
        </div>
      </>
    );
  };

  const renderAddExistingUser = id => {
    const userSelected = !!id;
    if (isEdit()) {
      return <></>;
    }
    return (
      <>
        <div className="sg-mgmt-form-row">Add existing user to this site:</div>
        <div className="sg-mgmt-form-row">{renderExistingUsers()}</div>
        {renderAddUser(userSelected)}
      </>
    );
  };

  const renderUserDetails = () => {
    if (isEdit()) {
      return (
        <>
          {renderValue("Name", `${user.name_first} ${user.name_last}`)}
          {renderValue("Email", user.email)}
        </>
      );
    }
    return <></>;
  };

  const renderUserInfo = id => {
    return (
      <div className="sg-mgmt-user-form-info">
        <h2>User Info</h2>
        <div className="sg-mgmt-form-container">
          {renderUserDetails()}
          {renderAddExistingUser(id)}
          {renderPasswordReset()}
          <p>&nbsp;</p>
        </div>
      </div>
    );
  };

  const renderCheckDisabled = label => (
    <div className="sg-mgmt-user-access-section-field">
      <CheckFieldSmallDisabled label={label}/>
    </div>
  );

  const renderCheck = (fieldName, label) => (
    <div className="sg-mgmt-user-access-section-field">
      <CheckFieldSmall fieldName={fieldName} label={label} />
    </div>
  );

  const renderCheckDependent = (fieldName, label, value) => {
    if (value) {
      return renderCheck(fieldName, label);
    }
    return renderCheckDisabled(label);
  };

  const renderAccessAnalytics = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Analytics</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][analytics_view]", "View")}
        {renderCheckDependent(
          "user[permission][reports_view]",
          "Reports",
          values.user.permission.analytics_view
        )}
      </div>
    </div>
  );

  const renderAccessCache = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Cache</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][cache_view]", "View")}
      </div>
    </div>
  );

  const renderAccessAttendees = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Attendees</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][attendees_view]", "View")}
        {renderCheckDependent(
          "user[permission][attendees_edit]",
          "Edit",
          values.user.permission.attendees_view
        )}
        {renderCheckDependent(
          "user[permission][attendees_delete]",
          "Delete",
          values.user.permission.attendees_view
        )}
      </div>
    </div>
  );

  const renderAccessCommunications = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Communications</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][communications_view]", "View")}
        {renderCheckDependent(
          "user[permission][communications_edit]",
          "Edit",
          values.user.permission.communications_view
        )}
        {renderCheckDependent(
          "user[permission][communications_send]",
          "Invite",
          values.user.permission.communications_view
        )}
      </div>
    </div>
  );

  const renderAccessDashboard = () => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Dashboard</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][dashboard_default_edit]", "Edit Default")}
      </div>
    </div>
  );

  const renderAccessTicketing = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Ticketing</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][ticketing_view]", "View")}
        {renderCheckDependent(
          "user[permission][ticketing_edit]",
          "Edit",
          values.user.permission.ticketing_view
        )}
      </div>
    </div>
  );

  const renderAccessHousing = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Housing</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][housing_view]", "View")}
        {renderCheckDependent(
          "user[permission][housing_edit]",
          "Edit",
          values.user.permission.housing_view
        )}
      </div>
    </div>
  );

  const renderAccessForms = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Forms</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][forms_view]", "View")}
        {renderCheckDependent(
          "user[permission][forms_edit]",
          "Edit",
          values.user.permission.forms_view
        )}
      </div>
    </div>
  );

  const renderAccessStaticFiles = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Static Files</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][static_files_view]", "View")}
        {renderCheckDependent(
          "user[permission][static_files_edit]",
          "Edit",
          values.user.permission.static_files_view
        )}
      </div>
    </div>
  );

  const renderAccessWebPages  = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Web Pages</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][web_pages_view]", "View")}
        {renderCheckDependent(
          "user[permission][web_pages_edit]",
          "Edit",
          values.user.permission.web_pages_view
        )}
      </div>
    </div>
  )

  const renderAccessMeetings = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Meetings</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][meetings_view]", "View")}
        {renderCheckDependent(
          "user[permission][meetings_edit]",
          "Edit",
          values.user.permission.meetings_view
        )}
        {renderCheckDependent(
          "user[permission][meetings_delete]",
          "Delete",
          values.user.permission.meetings_view
        )}
      </div>
    </div>
  );

  const renderAccessMeetingRequests = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Meeting Requests</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][meeting_requests_view]", "View")}
        {renderCheckDependent(
          "user[permission][meeting_requests_edit]",
          "Edit",
          values.user.permission.meeting_requests_view
        )}
        {renderCheckDependent(
          "user[permission][meeting_requests_approval]",
          "Approve",
          values.user.permission.meeting_requests_view
        )}
      </div>
    </div>
  );

  const renderAccessParticipants = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Participants</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][participants_view]", "View")}
        {renderCheckDependent(
          "user[permission][participants_edit]",
          "Edit",
          values.user.permission.participants_view
        )}
        {renderCheckDependent(
          "user[permission][participants_delete]",
          "Delete",
          values.user.permission.participants_view
        )}
      </div>
    </div>
  );

  const renderAccessSessions = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Sessions</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][sessions_view]", "View")}
        {renderCheckDependent(
          "user[permission][sessions_edit]",
          "Edit",
          values.user.permission.sessions_view
        )}
        {renderCheckDependent(
          "user[permission][sessions_delete]",
          "Delete",
          values.user.permission.sessions_view
        )}
      </div>
    </div>
  );

  const renderAccessFeatureFlags = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Feature Flags</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][feature_flag_view]", "View")}
        {renderCheckDependent(
          "user[permission][feature_flag_edit]",
          "Edit",
          values.user.permission.feature_flag_view
        )}
      </div>
    </div>
  );

  const renderAccessSpeakers = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Speakers</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][speakers_view]", "View")}
        {renderCheckDependent(
          "user[permission][speakers_edit]",
          "Edit",
          values.user.permission.speakers_view
        )}
        {renderCheckDependent(
          "user[permission][speakers_delete]",
          "Delete",
          values.user.permission.speakers_view
        )}
      </div>
    </div>
  );

  const editEnabled = () => {
    if (currentUser.role === "basic" && !currentUser.permission.users_edit) {
      return false;
    }
    return true;
  };

  const renderAccessUsers = values => (
    <div className="sg-mgmt-user-access-section">
      <div className="sg-mgmt-user-access-section-name">Users</div>
      <div className="sg-mgmt-user-access-section-fields">
        {renderCheck("user[permission][users_view]", "View")}
        {renderCheckDependent(
          "user[permission][users_edit]",
          "Edit",
          values.user.permission.users_view
        )}
        {renderCheckDependent(
          "user[permission][users_invite]",
          "Invite",
          values.user.permission.users_view
        )}
      </div>
    </div>
  );

  const renderAccessDetails = values => (
    <div className="sg-mgmt-user-form-access">
      <h2>Access Details</h2>
      <div className="sg-mgmt-user-access-container">
        {renderAccessAnalytics(values)}
        {/* {renderAccessAttendees(values)} */}
        {renderAccessCache(values)}
        {renderAccessCommunications(values)}
        {renderAccessDashboard()}
        {renderAccessFeatureFlags(values)}
        {renderAccessForms(values)}
        {renderAccessHousing(values)}
        {renderAccessMeetings(values)}
        {renderAccessMeetingRequests(values)}
        {renderAccessParticipants(values)}
        {renderAccessSessions(values)}
        {renderAccessStaticFiles(values)}
        {renderAccessTicketing(values)}
        {/* {renderAccessSpeakers(values)} */}
        {renderAccessUsers(values)}
        {renderAccessWebPages(values)}
      </div>
    </div>
  );

  const renderAddEditUser = () => {
    return (
      <Formik
        initialValues={{
          user: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          })
            .then(response => {
              if (response.data.error === null) {
                update(response.data.user);
                reset();
                alertSuccess(`User ${formConfig.alert} successfully`);
              } else {
                alertError(response.data.error);
                setSubmitting(false);
              }
            })
            .catch(error => {
              alertHttpError(error);
            });
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            {renderUserInfo(values.user.id)}
            {renderAccessDetails(values)}
            {renderDeveloper()}
            <Stack direction="row" spacing={2}>

              {editEnabled() ? renderSubmitButton(
                "Save",
                isSubmitting
              ):""}
              {renderCancelButton("Cancel", reset)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <div>{renderAddEditUser()}</div>;
};


EventUsersForm.defaultProps = {
  eventUsers: [],
  reset: () => {
    alertError("not implemented");
  },
  update: () => {
    alertError("not implemented");
  }
};

EventUsersForm.propTypes = {
  eventUsers: PropTypes.array,
  reset: PropTypes.func,
  update: PropTypes.func,
  user: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired
};

export default EventUsersForm;
