import React, { useContext } from "react";
import PropTypes from "prop-types";

import { FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import urljoin from "url-join";

import WidgetDataSetSelect from "@dashboard/form/WidgetDataSetSelect";
import WidgetFilterByRole from "@dashboard/form/WidgetFilterByRole";
import WidgetFilterByStatus from "@dashboard/form/WidgetFilterByStatus";
import WidgetFilterByTag from "@dashboard/form/WidgetFilterByTag";
import WidgetFilterByType from "@dashboard/form/WidgetFilterByType";
import WidgetHeaderField from "@dashboard/form/WidgetHeaderField";
import WidgetMetadataFieldSelect from "@dashboard/form/WidgetMetadataFieldSelect";
import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";

const PieWidgetSettings = (props) => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard, metadataFields, tags, types, statuses } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      widget_data_set: widget?.widget_data_set || "participantsByMetadata",
      widget_header: widget?.widget_header || "",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 1,
      widget_config: {
        metadata_field_id: widget?.widget_config?.metadata_field_id || "",
        filters: widget?.widget_config?.filters || {
          role: "",
          status: "",
          tag: "",
          type: ""
        },
        pie: widget?.widget_config?.pie || {
          colorScheme: "yellow"
        }
      }
    }
  });
  const dataSetWatch = watch("widget_data_set");

  const dataSets = [{ value: "participantsByMetadata", label: "Participants By Custom Field Value" }];

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = (submitData) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    // add in type
    submitData.widget_type = "pie";
    submitData.widget_size = 1;

    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then((result) => {
        onUpdate();
        closeModal();
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderPieSelections = () => {
    return (
      <div className="mb-4">
        <h3>Select color scheme:</h3>
        <div className="mt-2 flex-1 flex-grow align-bottom">
          <FormControl>
            <Controller
              name={`widget_config[pie][colorScheme]`}
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <RadioGroup {...field} onChange={onChange} value={value}>
                  <FormControlLabel value="yellow" control={<Radio />} label="Yellow" />
                  <FormControlLabel value="blue" control={<Radio />} label="Blue" />
                  <FormControlLabel value="mixed" control={<Radio />} label="Mixed" />
                </RadioGroup>
              )}
            />
          </FormControl>
        </div>
      </div>
    );
  };

  const renderDataSetConfig = () => {
    switch (dataSetWatch) {
      case "participantsByMetadata":
        return <WidgetMetadataFieldSelect control={control} metadataFields={metadataFields} />;
      case "somethingElse":
        return <div>Something else</div>;
      default:
        return <></>;
    }
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <WidgetHeaderField control={control} errors={errors} placeholder={"i.e. Registration Status"} />
          <WidgetDataSetSelect control={control} dataSets={dataSets} errors={errors} />
          {renderDataSetConfig()}
          <WidgetFilterByRole control={control} />
          <WidgetFilterByTag control={control} tags={tags} />
          <WidgetFilterByType control={control} types={types} />
          <WidgetFilterByStatus control={control} statuses={statuses} />
          {renderPieSelections()}
        </div>
        <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={isSubmitting} isEdit={!!widget?.id} />
      </form>
    );
  };

  return (
    <div className="group relative bg-white">
      <div>
        <h2>Pie widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

PieWidgetSettings.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default PieWidgetSettings;
