import React, { useContext, useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";

import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import ContentFrame from "@shared/ContentFrame";
import { renderButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import { formatTime } from "@shared/TimeUtils";
import { alertError } from "@shared/Alerts";
import FormSubmissionModal from "./form_submissions/FormSubmissionModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Link as MuiLink
} from "@mui/material";
import PeopleEventParticipantsViewModal from "@people/event_participants/PeopleEventParticipantsViewModal";
const FormsEdit = () => {
  const history = useHistory();
  const { id, form_type } = useParams();
  const { user } = useContext(EventUserContext);
  const [form, setForm] = useState(null);
  const [formSubmissions, setFormResponses] = useState(null);
  const { apiRoot, event } = useContext(EventContext).values;
  const [viewItem, setViewItem] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const confirm = useConfirm();
  const [ participantId, setParticipantId ] = useState(null);


  const metaQuery = useQuery({
    queryKey: ["participantMeta"],
    staleTime: (10 * 1000),      // 10 seconds
    cacheTime: (10 * 60 * 1000), // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/participants/meta"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const columns = [
    {
      headerName: "Attendee",
      field: "data.event_participant.full_name",
      valueGetter: params =>
        params.row.event_participant ? params.row.event_participant.name_full : null,
      renderCell: params => {
        return  params.value ? <MuiLink component="button" className="cursor-pointer" onClick={() => {setParticipantId(params.row.event_participant.id)}}>{params.value}</MuiLink> : "N/A";
      }
    },
    {
      headerName: "Submitted At",
      field: "updated_at",
      renderCell: params => {
        return formatTime(params.value);
      }
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      minWidth: 200,
      flex: 1,
      getActions: params => [
        renderViewAction(params.row),
        renderDeleteAction(params.row)
      ]
    }
  ];

  useEffect(() => {
    const fetchForm = async () => {
      const result = await axios(urljoin(apiRoot, `/forms/${id}`));
      setForm(result.data.form);
    };

    const fetchFormResponses = async () => {
      const result = await axios(
        urljoin(apiRoot, `/forms/${id}/form_submissions`)
      );
      setFormResponses(result.data.form_submissions);
    };

    fetchForm();
    fetchFormResponses();
  }, [apiRoot, id]);

  const renderViewAction = item => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            setViewItem(item);
            openViewModal();
          }}
        >
          View
        </span>
      </>
    );
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.forms_edit) {
      return false;
    }
    return true;
  };

  const attemptDelete = submission => {
    confirm({
      title: "Confirm removal",
      description:
        "Are you sure you want to remove this submission? This cannot be undone."
    })
      .then(() => {
        performDelete(submission);
      })
      .catch(err => {
        alertError(err);
      });
  };

  const renderDeleteAction = item => {
    if (editEnabled()) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              attemptDelete(item);
            }}
          >
            Delete
          </span>
        </>
      );
    }
    return <></>;
  };

  const performDelete = submission => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/forms/:form_id/form_submissions/", `${submission.id}`),
      method: "DELETE"
    })
      .then(response => {
        if (response.data.error === null) {
          deleteSubmission(submission);
          alertSuccess("Response deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const openViewModal = () => {
    setViewModalVisible(true);
  };

  const closeViewModal = () => {
    setViewModalVisible(false);
  };

  const resetViewModal = () => {
    closeViewModal();
    setViewItem(null);
  };

  const deleteSubmission = mt => {
    setFormResponses(formSubmissions.filter(m => m.id !== mt.id));
  };

  const renderFormResponseTable = () => {
    if (formSubmissions != null) {
      return (
        <GrowlTable
          columns={columns}
          items={formSubmissions}
          sortColumn="created_at"
          sortDirection="asc"
          tableName={`${event.slug}-form-submissions`}
        />
      );
    }
    return <Loading />;
  };

  const renderBackButton = () => {
    return (
      <Link to={`/forms/${form_type}`}>{renderButton("Done", () => {})}</Link>
    );
  };


  const renderViewModal = () => {
    if (!participantId) {
      return <></>;
    }

    return (
      <PeopleEventParticipantsViewModal
        apiRoot={apiRoot}
        metadataFields={metaQuery.data.metadataFields}
        participantId={participantId}
        modalVisible={true}
        regFields={metaQuery.data.regFields}
        resetModal={()=>setParticipantId(null)}
      />
    );
  };

  return (
    <ContentFrame>
      <div>
        <h1>Form: {form ? form.name : ""} </h1>

        <a
          target="_blank"
          href={`/reports/events/${event.slug}/forms/${id}`}
          rel="noreferrer"
        >
          {renderButton("Download Report", () => {})}
        </a>
        <br />
        <br />
        {renderFormResponseTable()}
        <br />
        <br />
        {renderBackButton()}
      </div>
      <FormSubmissionModal
        closeModal={closeViewModal}
        modalVisible={viewModalVisible}
        resetModal={resetViewModal}
        formSubmission={viewItem}
        form={form}
      />
      {renderViewModal()}
    </ContentFrame>
  );
};

export default FormsEdit;
