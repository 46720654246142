import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { Stack } from "@mui/material";
import axios from "axios";
import urljoin from "url-join";

import EventUserContext from "@event/EventUserContext";
import { alertHttpError } from "@shared/Alerts";
import { renderButton, renderCancelButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";
import { formatTime, formatTimeFriendly } from "@shared/TimeUtils";
import { isDeveloper } from "@shared/UserUtils";

import PeopleBlankEventParticipant from "./PeopleBlankEventParticipant";

const PeopleEventParticipantsViewModal = props => {
  const {
    apiRoot,
    participantId,
    modalVisible,
    metadataFields,
    regFields,
    resetModal
  } = props;
  const [fetched, setFetched] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const { user } = useContext(EventUserContext);
  const [selectedParticipant, setSelectedParticipant] = useState(
    PeopleBlankEventParticipant
  );

  Modal.setAppElement("#root");

  useEffect(() => {
    const fetchParticipant = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/participants", `/${participantId}`)
        );
        setSelectedParticipant(result.data.participant);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchParticipant();
  }, [apiRoot, participantId]);

  const renderField = (label, value) => {
    return (
      <div key={label} className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderDeveloper = () => {
    if (isDeveloper(user) && selectedParticipant.gid) {
      return (
        <div className="sg-mgmt-modal-view-section">
          <h2>Developer Details</h2>
          {renderField("Individual GID", selectedParticipant.individual.gid)}
          {renderField("Participant GID", selectedParticipant.gid)}
          <div className="py-2" />
          <h2>Data Block</h2>
          {Object.keys(selectedParticipant.data).map(item =>
            renderField(item, selectedParticipant.data[item])
          )}
        </div>
      );
    }
    return <></>;
  };

  const renderTags = () => {
    return (
      <div>
        <div className="my-4 flex">
          {selectedParticipant.tags.map(tag => (
            <div
              className="mr-2 flex-initial bg-sg-orange bg-opacity-70 px-2 py-1 text-white"
              key={`tag-${tag.gid}`}
            >
              {tag.name}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPhoto = () => {
    if (selectedParticipant.photo_url) {
      return (
        <div className="sg-mgmt-modal-view-section">
          <h2>Photo</h2>
          <img
            className="sg-mgmt-form-speaker-photo-container cursor-pointer"
            src={selectedParticipant.photo_url}
            alt="Event Participant"
          />
        </div>
      );
    }
    return <></>;
  };

  const renderSpeakerDetails = () => {
    if (selectedParticipant.role === "speaker") {
      return (
        <div className="sg-mgmt-modal-view-section">
          <h2>Speaker Details</h2>
          {renderField("Bio", selectedParticipant.bio)}
        </div>
      );
    }
    return <></>;
  };

  const renderEventFields = () => {
    const fields = [];
    regFields.forEach(f => {
      const answer = selectedParticipant.registration_answers[f.slug];
      const answerValue = String(answer || "(none)");
      fields.push(renderField(f.label, answerValue));
    });
    return fields;
  };

  const renderTickets = () => {
    let tickets = {}
    selectedParticipant.ticketing_participant_tickets.forEach(participant_ticket => {
      if (tickets[participant_ticket.ticket_type.name] === undefined) {
        tickets[participant_ticket.ticket_type.name] = 1;
      } else {
        tickets[participant_ticket.ticket_type.name] = tickets[participant_ticket.ticket_type.name] + 1;
      }
    });
    return (
      Object.entries(tickets).map(
        ([k, v], i) => `${k} (${v})`
      ).join(', ')
    );
  }

  const renderMetadata = () => {
    const fields = [];
    metadataFields.forEach(f => {
      const answer = selectedParticipant.metadata.find(
        md => md.field_slug == f.slug
      )?.value;
      const answerValue = String(answer || "(none)");
      fields.push(renderField(f.label, answerValue));
    });
    return fields;
  };

  const renderBool = bool => {
    return bool ? "true" : "false";
  };

  const renderTime = timestamp => {
    if (!timestamp || !selectedParticipant || !selectedParticipant.event) {
      return "";
    }
    return formatTime(timestamp, selectedParticipant.event.time_zone);
  };

  const renderHistoryButton = () => {
    if (!showHistory) {
      return renderButton("History", () => {
        setShowHistory(true);
      });
    }
    return renderButton("Details", () => {
      setShowHistory(false);
    });
  };

  const renderHistoryEntries = items => {
    const sorted = items; // TODO: Actual sort
    return sorted.map(i => renderHistoryEntry(i));
  };

  const renderHistoryEntry = item => {
    let entry = item.change_description;
    if (item.change_value_new !== null) {
      entry = `${entry}: ${item.change_value_new}`;
    }
    if (item.change_value_old !== null && item.change_value_old !== "") {
      entry = `${entry} (was: ${item.change_value_old})`;
    }
    return (
      <div key={item.gid} className="mb-2">
        <div>{entry}</div>
        <div className="italic">
          {formatTimeFriendly(
            item.created_at,
            selectedParticipant.event.time_zone
          )}{" "}
          by {item.change_by}
        </div>
      </div>
    );
  };

  const renderParticipantHistory = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{`Participant: ${selectedParticipant.name_first} ${selectedParticipant.name_last}`}</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <div className="flex">
              <div className="w-full">
                <div className="sg-mgmt-modal-view-section">
                  <h2>History</h2>
                  {renderHistoryEntries(
                    selectedParticipant.participant_changes
                  )}
                </div>
                <Stack direction="row" spacing={2}>
                  {renderCancelButton("Done", resetModal)}
                  {renderHistoryButton()}
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderParticipantDetails = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{`Participant: ${selectedParticipant.name_first} ${selectedParticipant.name_last}`}</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <div className="flex">
              <div className="w-1/2 pr-4">
                <div className="sg-mgmt-modal-view-section">
                  <h2>Personal Details</h2>
                  {renderField("First Name", selectedParticipant.name_first)}
                  {renderField("Last Name", selectedParticipant.name_last)}
                  {renderField("Company", selectedParticipant.company)}
                  {renderField("Job Title", selectedParticipant.job_title)}
                </div>
                <div className="sg-mgmt-modal-view-section">
                  <h2>Contact Information</h2>
                  {renderField(
                    "Address (line 1)",
                    selectedParticipant.work_address_street_1
                  )}
                  {renderField(
                    "Address (line 2)",
                    selectedParticipant.work_address_street_2
                  )}
                  {renderField("City", selectedParticipant.work_address_city)}
                  {renderField(
                    "State/Province",
                    selectedParticipant.work_address_state
                  )}
                  {renderField(
                    "ZIP/Postal Code",
                    selectedParticipant.work_address_postcode
                  )}
                  {renderField(
                    "Country",
                    selectedParticipant.work_address_country
                  )}
                  {renderField("Email", selectedParticipant.email)}
                  {renderField("Phone", selectedParticipant.work_phone)}
                </div>
                {renderSpeakerDetails()}
                {renderDeveloper()}
              </div>
              <div className="w-1/2">
                <div className="sg-mgmt-modal-view-section">
                  <h2>Status</h2>
                  {renderField("Role", selectedParticipant.role)}
                  {renderField("Status", selectedParticipant.status)}
                  {renderField(
                    "Attended",
                    renderBool(selectedParticipant.attended)
                  )}
                  {renderField(
                    "Test Flag",
                    renderBool(selectedParticipant.test_flag)
                  )}
                </div>
                <div className="sg-mgmt-modal-view-section">
                  <h2>Timestamps</h2>
                  {renderField(
                    "Created at",
                    renderTime(selectedParticipant.created_at)
                  )}
                  {renderField(
                    "Updated at",
                    renderTime(selectedParticipant.updated_at)
                  )}
                  {renderField(
                    "Registered at",
                    renderTime(selectedParticipant.registered_at)
                  )}
                </div>
                <div className="sg-mgmt-modal-view-section">
                  <h2>Registration Answers</h2>
                  {renderEventFields()}
                </div>
                <div className="sg-mgmt-modal-view-section">
                  <h2>Tickets</h2>
                  {renderTickets()}
                </div>
                <div className="sg-mgmt-modal-view-section">
                  <h2>Custom Fields</h2>
                  {renderMetadata()}
                </div>
                {renderPhoto()}
                <div className="sg-mgmt-modal-view-section">
                  <h2>Tags</h2>
                  {renderTags()}
                </div>
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderCancelButton("Done", resetModal)}
              {renderHistoryButton()}
            </Stack>
          </div>
        </div>
      </div>
    );
  };

  const renderViewParticipantModal = () => {
    if (fetched) {
      if (showHistory) {
        return renderParticipantHistory();
      }
      return renderParticipantDetails();
    }
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Participant:</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <Loading />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="View Participant"
    >
      {renderViewParticipantModal()}
    </Modal>
  );
};

PeopleEventParticipantsViewModal.propTypes = {
  apiRoot: PropTypes.string.isRequired,
  participantId: PropTypes.number.isRequired,
  metadataFields: PropTypes.array.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  regFields: PropTypes.array.isRequired,
  resetModal: PropTypes.func.isRequired
};

export default PeopleEventParticipantsViewModal;
