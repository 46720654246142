import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { formatTime } from "@shared/TimeUtils";
import Loading from "@shared/Loading";
import { renderCancelButton } from "@shared/FormUtils";

const FormSubmissionModal = props => {
  const { modalVisible, resetModal, formSubmission,viewModalVisible,  form } = props;

  Modal.setAppElement("#root");

  const renderField = (label, value) => {
    return (
      <div className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };
  
  const getFormQuestions = () => {
    const sorted_questions =  form.pages.filter(
      x=>!x.archived
    ).sort((a,b)=> 
      a.sort_order - b.sort_order
    ).map(x=>
      x.questions.filter(x=>!x.hide_on_reports).filter(x=>
        !x.archived
      ).sort((a,b)=> a.sort_order - b.sort_order)).flat()
    return sorted_questions
  };

  const renderFormAnswers = (question) => {
    return (
      renderField(question.label, (formSubmission.response||{})[question.attribute_id])
    )
  };

  const renderViewSubmission = () => {
    if(!formSubmission) return (<Loading />);
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Form Submission</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <div className="sg-mgmt-modal-view-section">
              <h2>Details</h2>
              {renderField("Submitted At", formatTime(formSubmission.updated_at))}
              {renderField("Attendee", formSubmission.event_participant ? formSubmission.event_participant.name_full : "N/A")}
            </div>
            <div className="sg-mgmt-modal-view-section">
              <h2>Submission Answers</h2>
              {getFormQuestions().map(question => (
                renderFormAnswers(question)
              ))}
            </div>
            <div className="sg-mgmt-modal-view-section">
              {renderCancelButton("Done", resetModal)}
            </div>
          </div>
        </div>
      </div>
    );
  };



  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Add Speaker"
    >
      {renderViewSubmission()}
    </Modal>
  );
};

FormSubmissionModal.propTypes = {
  form: PropTypes.object,
  formSubmission: PropTypes.object,
  modalVisible: PropTypes.bool.isRequired,
  resetModal: PropTypes.func.isRequired
};

export default FormSubmissionModal;
