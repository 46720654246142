import React, { useContext, useState } from "react";

import axios from "axios";
import urljoin from "url-join";

import { formatTime } from "@shared/TimeUtils";
import GrowlTable from "@shared/GrowlTable";
import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";
import EventContext from "@event/EventContext";
import { renderButton, renderCancelButton, renderDeleteButton } from "@shared/FormUtils";
import TicketRequestWidgetSettings from "./TicketRequestWidgetSettings"
import { alertError, alertSuccess } from "@shared/Alerts";
import { useConfirm } from "material-ui-confirm";

const TicketRequestWidget = () => {
  const { editMode, widget, values } = useContext(WidgetContext);
  const { apiRoot } = useContext(EventContext).values;
  const [tableItems, setTableItems] = useState(values);
  const confirm = useConfirm();

  const getRequestType = (row) => {
    if (row.ticket_type !== null) return 'ticket';
    if (row.package_type !== null) return 'package';
    return '';
  }

  const renderTicketName = (row) => {
    switch (getRequestType(row)) {
      case 'ticket':
        return row.ticket_type.name;
      case 'package':
        return row.package_type.name;
      default:
        return '';
    }
  }

  const ticketRequestUpdate = (approved, row, type) => {
    let formUrl = urljoin(apiRoot, `/ticketing/am_ticket_requests/${row.id}/${approved ? 'approve' : 'deny'}`);
    const formData = new FormData();
    formData.set("type", type);
    formData.set("gid", row.gid);

    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: formUrl,
      method: "POST",
      data: formData
    })
      .then((response) => {
        if (response.data.error === null) {
          alertSuccess(`Ticket request successfully ${approved ? 'approved' : 'denied'}.`);
          setTableItems(response.data.am_ticket_requests);
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertError(error);
      });
  }

  const ticketRequestDelete = (row) => {
    let formUrl = urljoin(apiRoot, `/ticketing/am_ticket_requests/${row.id}`);
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: formUrl,
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error === null) {
          alertSuccess(`Ticket request successfully deleted.`);
          console.log(response.data)
          let newTableItems = tableItems.filter((item) => item.id !== parseInt(response.data.am_ticket_request_id));
          setTableItems(newTableItems);
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertError(error);
      });
  }

  const renderApproveButton = (row) => {
    switch (getRequestType(row)) {
      case 'ticket':
        if (row.ticket_type.available_tickets > 0) { 
          return renderButton("Approve", () => ticketRequestUpdate(true, row, 'ticket'));
        }
        return renderButton("Approve", () => {}, {variant: 'outlined', disabled: true})
      case 'package':
        if (row.package_type.available_packages > 0) { 
          return renderButton("Approve", () => ticketRequestUpdate(true, row, 'package'));
        }
        return renderButton("Approve", () => {}, {variant: 'outlined', disabled: true})
      default:
        return '';
    }
  }

  const renderDenyButton = (row) => {
    switch (getRequestType(row)) {
      case 'ticket':
        return (
          renderCancelButton("Deny", () => ticketRequestUpdate(false, row, 'ticket'))
        );
      case 'package':
        return (
          renderCancelButton("Approve", () => ticketRequestUpdate(false, row, 'package'))
        );
      default:
        return '';
    }
  }

  const attemptDelete = (row) => {
    confirm({
      title: "Confirm removal",
      description:
        "Are you sure you want to delete this ticket request? This cannot be undone."
    }).then(() => {
      ticketRequestDelete(row);
    })
    .catch(err => {
      alertError(err);
    });
  }

  const renderDelete = (row) => {
    return (
      renderDeleteButton("Delete", () => attemptDelete(row))
    );
  }

  const renderActions = (row) => {
    if (row.approved === null) {
    return (
      <>
        <span className="mr-2">{renderApproveButton(row)}</span>
        <span className="mr-2">{renderDenyButton(row)}</span>
        <span>{renderDelete(row)}</span>
      </>
    );
    }
    return (
      <div>
        <span className="text-black border-none">{row.approved ? "approved" : "denied"}</span>
        <br />
        <span className="text-sm text-sg-comms-saved">
          {row.updated_by} | {row.approved ? formatTime(row.approved_at) : formatTime(row.denied_at)}
        </span>
      </div>
    );
  }

  const columns = [
    {
      headerName: "Requested By",
      field: "requested_by",
      flex: 1,
      renderCell: params => <>{params.row.event_participant_am.name_full}</>
    },
    {
      headerName: "Time",
      field: "time",
      flex: 1,
      renderCell: params => <span className="text-sm text-sg-comms-saved">{formatTime(params.row.created_at)}</span>
    },
    {
      headerName: "Ticket Name",
      field: "name",
      flex: 1,
      renderCell: params => <>{renderTicketName(params.row)}</>
    },
    {
      headerName: "Customer/Partner",
      field: "customer_partner",
      flex: 0,
      renderCell: params => (
        <div>
          <span className="text-black">
            {params.row.event_participant_customer.name_full}
          </span>
          <br />
          <span className="text-sm text-sg-comms-saved">
            {params.row.event_participant_customer.company}
          </span>
        </div>
      )
    },
    {
      headerName: "Action",
      field: "action",
      minWidth: 300,
      flex: 1,
      renderCell: params => <>{renderActions(params.row)}</>
    }
  ];

  const renderContent = () => {
    if (tableItems.length === 0) return <>No ticket requests available</>;
    return (
      <div>
        <GrowlTable
          columns={columns}
          items={tableItems}
          sortField="name"
          sortDirection="asc"
          tableName={`${widget.gid}-ticket-requests`}
          pageSizeInitial={10}
          pageSizeOptions={[5, 10, 25]}
        />
      </div>
    );
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Overview Settings" settingsComponent={TicketRequestWidgetSettings} />;
  };

  return (
    <WidgetContentFrame fluidHeight>
      <WidgetHeader />
      <div className="pt-4">{renderContent()}</div>
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default TicketRequestWidget;
